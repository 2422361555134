import React from "react";

import { white } from "theme/colors";
import { PopupContainer } from "./Popup.styles";

const Popup = (props) => {
  //PROPS
  const { show, toggle, type, message } = props;

  //PROPS
  return (
    <PopupContainer show={show} borderColor={white.default}>
      <div className="message-container">
        {type !== "loading" && (
          <button
            className="message-container-close"
            onClick={() => toggle(!show)}
          >
            x
          </button>
        )}
        {message}
      </div>
    </PopupContainer>
  );
};

export default Popup;
